import type { RoleSegmentCombination } from '@/components/sidebar/components/workspace-selector';
import { getApi } from '@/requests/api';
import { BASE_BACKEND_URL } from '@/requests/constants';
import { setCookie } from 'cookies-next';
import { DateTime } from 'luxon';
import type { RESPONSE_SUCCESS } from '../types';

export function setCustomerId(customerId: string) {
  setCookie('customer_id', customerId, {
    maxAge: 60 * 60 * 24, // 24 hours
    sameSite: 'strict',
    path: '/',
  });
}

export function setAuthCookies(data: RESPONSE_SUCCESS) {
  setCookie('token', data.token, {
    maxAge: 60 * 60 * 24, // 24 hours
    sameSite: 'strict',
    path: '/',
  });
  setCookie('user_id', data.user_id, {
    maxAge: 60 * 60 * 24, // 24 hours
    sameSite: 'strict',
    path: '/',
  });
  setCookie('organization_id', data.organization_id, {
    maxAge: 60 * 60 * 24, // 24 hours
    sameSite: 'strict',
    path: '/',
  });
  setCookie('token_exp', DateTime.fromISO(data.meta.lifetime_end).toISO(), {
    maxAge: data.meta.ttl, // 6 hours,
    sameSite: 'strict',
    path: '/',
  });
}

export async function handleRoleSegmentsCombination() {
  const data = await getApi<RoleSegmentCombination[]>({
    url: `${BASE_BACKEND_URL}/accounts/users/role_segment_combinations/`,
  });

  return data;
}

interface HandleHotpCode {
  code: number;
  userId?: string;
  mfaToken?: string;
}

interface HandleHotpCodeReturn {
  redirectUrl: string | null;
  isError: boolean;
  message?: string;
  mfaTOTP?: boolean;
  mfaHOTP?: boolean;
  isMFAActivation?: boolean;
  isIPAddressVerification?: boolean;
  mfaTOTPorHOTP?: boolean;
}

export function handleHotpCode({
  code,
  userId,
  mfaToken,
}: HandleHotpCode): HandleHotpCodeReturn {
  switch (code) {
    case AuthResponses.MISSING_CUSTOMER_ID:
      return {
        redirectUrl: null,
        isError: true,
        message: 'Kund-ID saknas eller är felaktigt',
      };
    case AuthResponses.USERNAME_PASSWORD:
      return {
        redirectUrl: null,
        isError: true,
        message: 'Fel användarnamn eller lösenord',
      };
    case AuthResponses.ID_HOTP:
      return {
        redirectUrl: null,
        isError: true,
        message: 'Felaktig kod',
      };
    case AuthResponses.SETUP_DATA:
      return {
        redirectUrl: `/login/setup/?userId=${userId}&token=${mfaToken}`,
        isError: false,
      };
    case AuthResponses.SETUP_USERNAME:
      return {
        redirectUrl: `/login/setup/?userId=${userId}&token=${mfaToken}&kind=username`,
        isError: false,
      };
    case AuthResponses.SETUP_PASSWORD:
      return {
        redirectUrl: `/login/setup/?userId=${userId}&token=${mfaToken}&kind=password`,
        isError: false,
      };
    case AuthResponses.PASSWORD_EXPIRATION:
      return {
        redirectUrl: `/login/credentials/password-expired`,
        isError: false,
      };
    case AuthResponses.MFA_DATA:
      return {
        redirectUrl: null,
        isError: true,
        message: 'Felaktig kod',
      };
    case AuthResponses.MFA_TOTP_OR_HOTP_REQUIRED:
      return {
        redirectUrl: null,
        isError: false,
        mfaTOTPorHOTP: true,
      };
    case AuthResponses.MFA_TOTP_REQUIRED:
      return {
        redirectUrl: null,
        isError: false,
        mfaTOTP: true,
      };
    case AuthResponses.MFA_HOTP_REQUIRED:
      return {
        redirectUrl: null,
        isError: false,
        mfaHOTP: true,
      };
    case AuthResponses.IP_ADDRESS_VERIFICATION:
      return {
        redirectUrl: null,
        isError: false,
        mfaHOTP: true,
        isIPAddressVerification: true,
      };

    case AuthResponses.MFA_ACTIVATION:
      return {
        redirectUrl: null,
        isError: false,
        isMFAActivation: true,
      };
  }

  if (code && !userId && !mfaToken) {
    return {
      redirectUrl: '/login/magic-link',
      isError: false,
      mfaHOTP: true,
    };
  }
  return {
    redirectUrl: '/dashboard',
    isError: false,
  };
}

export const AuthResponses = {
  MFA_TOTP_REQUIRED: 0,
  MFA_HOTP_REQUIRED: 1,
  MFA_TOTP_OR_HOTP_REQUIRED: 2,
  USERNAME_PASSWORD: 3, // wrong credentials
  MFA_ACTIVATION: 4,
  USER_TYPE: 5,
  IP_ADDRESS_UNKNOWN: 6,
  IP_ADDRESS_VERIFICATION: 7,
  PASSWORD_EXPIRATION: 8,
  MFA_DATA: 9,
  ID_HOTP: 10, // wrong id or token, may be expired
  SETUP_DATA: 11, // both username and password
  SETUP_USERNAME: 12, // only setup usernmae
  SETUP_PASSWORD: 13, // only password
  BANKID_DATA: 14,
  HOTP_DISTRIBUTION_FAILED: 15,
  HOTP_DISTRIBUTION_OK: 16, // email or SMS code sent
  HOTP_TIMEOUT: 17,
  UNAVAILABLE_AUTH_METHOD: 18,
  MISSING_CUSTOMER_ID: 19,
  MISSING_DOMAIN: 20,
  MISSING_SSN: 21,
  MISSING_BANKID_ORDER_REF: 22,
  TEST_BANKID_NOT_AUTHENTICATED: 23, // When testing bankid and the user is not already authenticated
} as const;
